.MainColumnForOverView {
	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
	background-image: radial-gradient(#8e0631,#600223);
	/* background-image: url(/public/plain.png); */
	background-repeat: no-repeat;
	background-size: cover;
	height: calc(100vh - 60px);
}
.overview-space{
	height: 20px;
}
.line_dd_overView {
	padding: 0px;
	margin-top: 8px;
	height: 1px;
	width: 80%;
	background-image: linear-gradient(to right, #cc9540, #f0cf67);
}
.borderSideGolden {
	height: 1px;
	width: 50px;
	background-image: linear-gradient(to right, #cc9540, #f0cf67);
}
.rowTitle {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.imageOverView {
	border: 1px solid #cc9540;
	width: 600px;
	padding: 3px;
}
.OverColumn {
	display: flex;
	flex-direction: column;
}
.WhiteText {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 2px;
	font-family: "Lora", serif;
	color: white;
}
.WhiteText-intro-overview {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 2px;
	font-family: "Lora", serif;
	color: white;
}
.golden-text {
	font-size: 26px;
	font-weight: 400;
	text-align: center;
	letter-spacing: 2px;
	font-family: "Lora", serif;
	color: #f0cf67;
	background-color: #e9cc6c;
	/* background-image: linear-gradient(to left, #cc9540, #f0cf67); */
	-webkit-background-clip: text; /* For Safari */
	background-clip: text;
	color: transparent;
}
.golden-text-title{
	font-size: 26px;
	font-weight: 400;
	text-align: center;
	letter-spacing: 2px;
	font-family: "Lora", serif;
	color: #f0cf67;
	background-color: #e9cc6c;
	/* background-image: linear-gradient(to left, #cc9540, #f0cf67); */
	-webkit-background-clip: text; /* For Safari */
	background-clip: text;
	color: transparent;
}
.golden-text-explore{
	font-size: 20px;
	font-weight: 400;
	text-align: center;
	letter-spacing: 2px;
	font-family: "Lora", serif;
	color: #f0cf67;
	background-color: #e9cc6c;
	/* background-image: linear-gradient(to left, #cc9540, #f0cf67); */
	-webkit-background-clip: text; /* For Safari */
	background-clip: text;
	color: transparent;
}

.OverViewPage {
	padding-left: 50px;
	padding-right: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
}
.hieghtForOverView{
	height: 30px;
}

@media (max-width: 1024px) {
	.OverViewPage {
		padding-left: 16px;
		padding-right: 16px;
		display: block;
		/* justify-content: space-between; */
		align-items: center;
		font-size: 14px;
	}
	.imageOverView {
		border: 1px solid #cc9540;
		width: 100%;
		padding: 3px;
	}
	.overViewSpace{
		height: 24px;
	}
	.MainColumnForOverView{
		height: fit-content;
	}
	
	}

	@media (max-width: 524px) {
		.golden-text-title{
			font-size: 22px;
			font-weight: 400;
			text-align: center;
			letter-spacing: 2px;
			font-family: "Lora", serif;
			color: #f0cf67;
			background-color: #e9cc6c;
			/* background-image: linear-gradient(to left, #cc9540, #f0cf67); */
			-webkit-background-clip: text; /* For Safari */
			background-clip: text;
			color: transparent;
		}
		.golden-text-explore{
			font-size: 18px;
			font-weight: 400;
			text-align: center;
			letter-spacing: 2px;
			font-family: "Lora", serif;
			color: #f0cf67;
			background-color: #e9cc6c;
			/* background-image: linear-gradient(to left, #cc9540, #f0cf67); */
			-webkit-background-clip: text; /* For Safari */
			background-clip: text;
			color: transparent;
		}
		.hieghtForOverView{
			height: 0px;
		}
		.WhiteText-intro-overview {
			display: flex;
			text-align: center;
			flex-direction: column;
			align-items: center;
			font-size: 14px;
			font-weight: 400;
			letter-spacing: 2px;
			font-family: "Lora", serif;
			color: white;
		}
		.overview-space{
			height: 10px;
		}
		}
