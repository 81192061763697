.golden-textMainHeading{
	font-size: 26px;
	font-weight: 400;
	text-align: center;
	letter-spacing: 2px;
	font-family: "Lora", serif;
	color: #f0cf67;
	background-color: #e9cc6c;
	/* background-image: linear-gradient(to left, #cc9540, #f0cf67); */
	-webkit-background-clip: text; /* For Safari */
	background-clip: text;
	color: transparent;
}
.golden-textSubHeading{
	font-size: 20px;
	font-weight: 400;
	text-align: center;
	letter-spacing: 2px;
	font-family: "Lora", serif;
	color: #f0cf67;
	background-color: #e9cc6c;
	/* background-image: linear-gradient(to left, #cc9540, #f0cf67); */
	-webkit-background-clip: text; /* For Safari */
	background-clip: text;
	color: transparent;
}
.FormForContactUS{
    border: 1px solid #cc9540;
    padding-left: 26px;
    padding-right: 26px;
    padding-top: 32px;
    padding-bottom: 32px;
}
.ColumnContactUs{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.email-icon {
    margin-right: 8px; /* Adjust the spacing between the icon and the text */
    color: #ffffff; /* Adjust the icon color as needed */
  }
.WhiteTextInfo{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 2px;
    font-family:  "Lora", serif;
    color: white;
  }
.ContactUsRow{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-left: 40px;
    padding-right: 40px;
}
.Textfield{
    padding-left: 16px;
    padding-right: 16px;

 width: 400px;
 height: 50px;
 border: 1px solid #ccc;
 border-radius: 0px;
 font-size: 18px;
 background-color: #ffffff;

}
.Textfield:focus{
 background-color: #FFFDD0;

    padding-left: 16px;
    padding-right: 16px;
    width: 400px;
 height: 50px;
 font-size: 18px;

    outline: none;
    border: 1px solid #ccc;
   }
.Textfield::placeholder{
    color: rgb(26, 24, 24);
    font-size: 16px;
}

.submit-button {
    /* padding: 10px 20px; */
    height: 50px;
    width: 200px;
    border: none;
    border-radius: 0px;
    background-color: #f59c03; /* Adjust the color to your preference */
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .submit-button:hover {
    background-color:#fcb339 ; /* Adjust the hover color */
  }

  .submit-button:focus {
    outline: none;
  }
  .footer {
    bottom: 0;
    left: 0;
    right: 0;
    /* background-color: #f1f1f1; */
    padding: 20px;
    color: white;

    text-align: center;
  }
  .MainImpColumn{
    background-image: radial-gradient(#8e0631,#600223);
    /* background-image: radial-gradient(#600223, #780127); */
    /* background-image: url(/public/plain.png); */
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh - 60px);
    
  }
  .ContactUsDetails{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 1024px) {
.ContactUsRow{
  display: block;
  justify-content: space-between;
  flex-direction: row;
  padding-left: 16px;
  padding-right: 16px;
}
.MainImpColumn{
  height:fit-content;
}


.Textfield::placeholder{
  color: rgb(26, 24, 24);
  font-size: 16px;
}

  }

  @media (max-width: 524px) {
    .golden-textMainHeading{
      font-size: 22px;
      font-weight: 400;
      text-align: center;
      letter-spacing: 2px;
      font-family: "Lora", serif;
      color: #f0cf67;
      background-color: #e9cc6c;
      /* background-image: linear-gradient(to left, #cc9540, #f0cf67); */
      -webkit-background-clip: text; /* For Safari */
      background-clip: text;
      color: transparent;
    }
    .golden-textSubHeading{
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      letter-spacing: 2px;
      font-family: "Lora", serif;
      color: #f0cf67;
      background-color: #e9cc6c;
      /* background-image: linear-gradient(to left, #cc9540, #f0cf67); */
      -webkit-background-clip: text; /* For Safari */
      background-clip: text;
      color: transparent;
    }
    .WhiteTextInfo{
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 2px;
      font-family:  "Lora", serif;
      color: white;
    }
    .footer {
      bottom: 0;
      left: 0;
      right: 0;
      /* background-color: #f1f1f1; */
      padding: 20px;
      color: white;
      font-size: 12px;
  
      text-align: center;
    }
    .ContactUsform{
      width: 100%;
    }
    .Textfield{
      width: 100%;
      }
      .Textfield:focus{
        width: 100%;
       }
  }