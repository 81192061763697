.planImage{
    height: 268px;
    width: 368px;
}
.planImage:hover{
    filter: brightness(60%);
    -webkit-filter: brightness(60%);
    -moz-filter: brightness(60%);
    -o-filter: brightness(60%);
    -ms-filter: brightness(60%);
    /* -webkit-transition: all 0.50s; */
    /* transition: all 0.50s; */
}
.imageRow{
    padding-left: 40px;
    padding-right: 40px;

display: flex;
flex-direction: row;
justify-content: center;
}
.MainColumnForLocation{
display: flex;
flex-direction: column;
justify-content: space-between;
}
.slick-prev, .slick-next {
    width: 30px;
    height: 30px;
  }
  
  .slick-prev:before, .slick-next:before {
    font-size: 30px;
    color: black; /* Change color as needed */
  }
  .ImageAutoSliderNew{
  
  }
  .slider-container .slick-dots li button:before {
    color: white !important; /* Change dot color to white */
  }
  
  .slider-container .slick-dots li.slick-active button:before {
    color: white !important; /* Change active dot color to white */
  }
  .slider-container {
    width:100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .slider-container img {
    width: 100%;
    height: 268px;
    border-radius: 0px;
    padding-left: 10px;
    padding-right: 10px;
   
  }