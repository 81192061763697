.MainColumnForHome{
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-image: radial-gradient(#8e0631,#600223);
	/* background-image: url(/public/plain.png); */
	background-repeat: no-repeat;
	background-size: cover;
	height: calc(100vh - 60px);

	
}
.lotusImage{
	height: 80px;
}
.HomeRow{
display: flex;
flex-direction: row;
justify-content: space-between;
padding-left: 40px;
padding-right: 120px;
}
.imageOverViewHome{
    border: 1px solid #cc9540;
	width: 600px;
	padding: 3px;
}
.mainpage{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-image:url(/public/circular.png);
    background-repeat: no-repeat;
	background-size: cover;
    opacity: 0.2; */
}
.logoInNewHome{
	height: 120px;
	margin-bottom: 50px;
	margin-top: 50px;
}
.golden-text-introduction{
	
		font-size: 16px;
		font-weight: 400;
		text-align: center;
		letter-spacing: 2px;
		font-family: "Lora", serif;
		color: #f0cf67;
		background-color: #e9cc6c;
		/* background-image: linear-gradient(to left, #cc9540, #f0cf67); */
		-webkit-background-clip: text; /* For Safari */
		background-clip: text;
		color: transparent;
	
}
.golden-text-unlock{
	
		font-size: 26px;
		font-weight: 400;
		text-align: center;
		letter-spacing: 2px;
		font-family: "Lora", serif;
		color: #f0cf67;
		background-color: #e9cc6c;
		/* background-image: linear-gradient(to left, #cc9540, #f0cf67); */
		-webkit-background-clip: text; /* For Safari */
		background-clip: text;
		color: transparent;
	
}

@media (max-width: 1024px) {
	.imageOverViewHome{
		margin-bottom: 16px;
		border: 1px solid #cc9540;
		width: 100%;
		padding: 3px;
	}
	.HomeRow{
		display: flex;
		flex-direction: column;
		/* justify-content: space-between; */
		padding-left: 16px;
		padding-right: 16px;
		}
		.MainColumnForHome{
			height:fit-content;
		
		}
		.logoInNewHome{
			height: 90px;
			margin-bottom: 30px;
			margin-top: 30px;
		}
	}
	@media (max-width: 524px) {
		.lotusImage{
			height: 70px;
		}
		.logoInNewHome{
			height: 80px;
			margin-bottom: 20px;
			margin-top: 20px;
		}
		.golden-text-introduction{
			font-size: 14px;
			font-weight: 400;
			text-align: center;
			letter-spacing: 2px;
			font-family: "Lora", serif;
			color: #f0cf67;
			background-color: #e9cc6c;
			/* background-image: linear-gradient(to left, #cc9540, #f0cf67); */
			-webkit-background-clip: text; /* For Safari */
			background-clip: text;
			color: transparent;
		}
		.golden-text-unlock{
	
			font-size: 24px;
			font-weight: 400;
			text-align: center;
			letter-spacing: 2px;
			font-family: "Lora", serif;
			color: #f0cf67;
			background-color: #e9cc6c;
			/* background-image: linear-gradient(to left, #cc9540, #f0cf67); */
			-webkit-background-clip: text; /* For Safari */
			background-clip: text;
			color: transparent;
		
	}
	}
