.MainColumnForLocation {
	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
	background-image: radial-gradient(#8e0631,#600223);
	/* background-image: url(/public/plain.png); */
	background-repeat: no-repeat;
	background-size: cover;
}
.column2 {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.rowforlocation {
	padding-left: 50px;
	padding-right: 50px;
	display: flex;
	justify-content: center;
	/* align-items: center; */

	font-size: 14px;
}
.imageMap {
	border: 1px solid #cc9540;
	width: 370px;
	padding: 3px;
}
.containerForIcons {
	height: 140px;
	width: 140px;
	border-radius: 10px;
	/* justify-content: center; */
	align-content: center;
	background-color: #ffffff;
}
.email-icon2 {
	height: 30px;
	color: #830c29;
}
.mobileViewFotLocations{
	display: flex;
	justify-content: center;
}
@media (max-width: 1024px) {

.rowforlocation{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-left: 16px;
	padding-right: 16px;
}
.imageMap {
	border: 1px solid #cc9540;
	width: 340px;
	padding: 3px;
}
.mobileSpaceForLocation{
	height: 30px;
}
}