.MainColumnForAmenities {
    display: flex;
    flex-direction: column;
    /* background-image: radial-gradient(#8e0631,#600223) */
    background-image: radial-gradient(#8e0631, #600223);

    /* background-image: url(/public/plain.png); */
    background-repeat: no-repeat;
    background-size: cover;
    /* height: calc(100vh - 60px); */
}
.golden-text-amenties{
    font-size: 26px;
	font-weight: 400;
	text-align: center;
	letter-spacing: 2px;
	font-family: "Lora", serif;
	color: #f0cf67;
	background-color: #e9cc6c;
	/* background-image: linear-gradient(to left, #cc9540, #f0cf67); */
	-webkit-background-clip: text; /* For Safari */
	background-clip: text;
	color: transparent;
}
.golden-text-amenties-intro{
    font-size: 20px;
    text-align: center;
	font-weight: 400;
	text-align: center;
	letter-spacing: 2px;
	font-family: "Lora", serif;
	color: #f0cf67;
	background-color: #e9cc6c;
	/* background-image: linear-gradient(to left, #cc9540, #f0cf67); */
	-webkit-background-clip: text; /* For Safari */
	background-clip: text;
	color: transparent;
}
.containerForIcons2 {
    padding-top: 40px;
    height: 140px;
    width: 140px;
    border-radius: 10px;
    /* justify-content: center; */
    /* align-content: center; */
    background-color: #ffffff
}

.planImageAm {
    height: 336px;
}

.rowForList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    padding-left: 40px;
    padding-right: 40px;
}

.columnfortext {
    display: block;
}

.rowforAm {
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.newColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.WhiteTextAM {
    text-align: left;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: left;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 2px;
    font-family: "Lora", serif;
    color: white;
}

.column3 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}
.mobileViewAmenites{
    display: none;
}

@media (max-width: 1024px) {

    .rowforAm {
        padding-left: 16px;
        padding-right: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;

    }

    .planImageAm {
        width: 100%;
    }
    .newColumn{
        display: none;
    }
    .mobileViewAmenites{
        display: block;
    }
}

@media (max-width: 524px) {
    .WhiteTextAM {
        text-align: left;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: left;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 2px;
        font-family: "Lora", serif;
        color: white;
    }
    .golden-text-amenties{
        font-size: 22px;
        font-weight: 400;
        text-align: center;
        letter-spacing: 2px;
        font-family: "Lora", serif;
        color: #f0cf67;
        background-color: #e9cc6c;
        /* background-image: linear-gradient(to left, #cc9540, #f0cf67); */
        -webkit-background-clip: text; /* For Safari */
        background-clip: text;
        color: transparent;
    }
    .golden-text-amenties-intro{
        font-size: 18px;
        text-align: center;
        font-weight: 400;
        text-align: center;
        letter-spacing: 2px;
        font-family: "Lora", serif;
        color: #f0cf67;
        background-color: #e9cc6c;
        /* background-image: linear-gradient(to left, #cc9540, #f0cf67); */
        -webkit-background-clip: text; /* For Safari */
        background-clip: text;
        color: transparent;
    }
  }