.carousel-container img {
    max-width: 100%;
    height: 100%;
  }

  .image-viewer {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
  }
  
  .app-bar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #333;
    color: white;
    padding: 10px;
  }
  
  .image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .image-container img {
    max-width: 100%;
    max-height: 100%;
    transition: transform 0.2s ease;
  }
  .NewImagesCollections:hover{
    filter: brightness(60%);
    -webkit-filter: brightness(60%);
    -moz-filter: brightness(60%);
    -o-filter: brightness(60%);
    -ms-filter: brightness(60%);
    /* -webkit-transition: all 0.50s; */
    /* transition: all 0.50s; */
}
