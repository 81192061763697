.App {
	background-image: radial-gradient(#8e0631,#600223);

}

.container_home {
	display: flex;
	align-items: center;
	background-image: radial-gradient(#8e0631,#600223);

	/* background-image: url(/public/plain.png); */
	height: calc(100vh - 60px);
	font-size: 14px;
	background-repeat: no-repeat;
	background-size: cover;
}
.ColumnView {
	position: relative;
	z-index: 1;
	height: 69px;
	display: flex;
	flex-direction: column;
	background-image: radial-gradient(#8e0631,#600223);
	width: 100%;
	/* background-image: url(/public/plain.png); */
}
.mainColum {
	width: 100% !important;
	overflow: hidden;
	max-width: 1480px;
	margin: 0px auto;
}
.navbar {
	/* l: 16, r: 16, t: 16, b: 15 */
	padding-left: 20px !important;
	padding-right: 20px !important;
	padding-top: 8px !important;

	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	color: white;
	position: fixed;
	font-family: "Lora", serif;
	letter-spacing: 2px;


	top: 0;
	width: 100%;
	z-index: 1000;
	max-width: 1480px;
	margin: 0px auto;
}
.line_d {
	padding: 0px;
	margin-top: 8px;
	height: 1px;
	background-image: linear-gradient(to right, #cc9540, #f0cf67);
}
.logo {
	font-size: 24px;

	font-weight: bold;
}

.vista {
	font-size: 24px;
	color: #f2b127;
}

.menu {
	display: flex;
	gap: 25px;

}

.menu-item {
	cursor: pointer;
	padding: 10px;
	transition: color 0.3s ease;
}

.menu-item:hover {
	color: #f2b127;
}

.menu-item.active {
	color: #f2b127;
}
.LandingImage {
	width: 500px;
}
.vertical-button {
	position: fixed;
	right: 0%; /* Position it on the right */
	top: 45%; /* Center it vertically */
	transform:rotate(270deg);
	transform-origin: 85%;
	padding: 8px 20px;
	background-color: #f59c03;
	color: white; /* Button text color */
	border: none; /* Remove border */
	border-radius: 5px 5px 0px 0px; /* Rounded corners on left side */
	cursor: pointer; /* Pointer cursor on hover */
	z-index: 1000; /* Ensure it stays on top */
  }
  
  .vertical-button:hover {
	background-color:#fcb339 
  }
  @media (max-width: 1024px) {
/* .desktop-view{
  display: none;
} */
.compay_logo{
	margin-right: 60px;
}
.menu {
	display: none;
	/* gap: 25px; */

} 
}