.enquiryText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 24px;
    font-weight: 500;
    font-family: "Lora", serif;
}

.Textfield2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;

    width: 400px;
    height: 50px;
    border: 1px solid #000000;
    border-radius: 0px;
    font-size: 18px;
    background-color: #ffffff;

}

.columnEnquiry {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

@media (max-width: 1024px) {
    .Textfield2 {
        width: 350px;
    }

}